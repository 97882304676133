<template>
      <div class="product-wrap instrumentDetail">
      <div class="product-pic">
       <div>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><img :src="instrument.picurl" /></div>
            <div class="swiper-slide" v-if="instrument.picurl2"><img :src="instrument.picurl2"  /></div>
            <div class="swiper-slide" v-if="instrument.picurl3"><img :src="instrument.picurl3"/></div>
            <div class="swiper-slide" v-if="instrument.picurl4"><img :src="instrument.picurl4"/></div>


          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>

      </div>
      <div class="product-info">
        <div class="product-name">{{ instrument.cnname }}</div>
      </div>
      <div class="product-detail">
        <div class="title">商品详情</div>
        <div class="content">
          <table border="1" cellspacing="0" cellpadding="10.013px">
            <thead>
              <tr>
                <th colspan="2">基本信息</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td class="name">中文名称</td>
              <td>{{ instrument.proName }}</td>
            </tr>
              <tr  v-if="instrument.proNumber">
                <td class="name">产品型号</td>
                <td>{{ instrument.proNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="other-view" v-if="instrument.proIntro">
        <div class="title">产品简介：</div>
        <div class="content">
          <div class="detail-content" style="text-indent:2em;">
            {{ instrument.proIntro }}
          </div>
        </div>
      </div>
      <div class="other-view" v-if="instrument.proDetails">
        <div class="title">产品概述：</div>
        <div class="content">
          <div class="detail-content">
            <div v-html="instrument.proDetails"></div>
          </div>

        </div>
      </div>
      <div class="other-view" v-if="instrument.technicalParameter">
        <div class="title">技术参数：</div>
        <div class="content">
          <div class="detail-content">
            <div v-html="instrument.technicalParameter"></div>
          </div>
        </div>
      </div>
      <div class="other-view" v-if="instrument.functionDes">
        <div class="title">功能特点：</div>
        <div class="content">
          <div class="detail-content" id ="dataContent">
            <div v-html="instrument.functionDes"></div>
          </div>
        </div>
      </div>
      <div class="recommend-title">热门推荐：</div>
      <div class="recommend">
        <div class="content">
          <ul>
            <li v-for="item in endHostList" :key="item">
              <router-link
                :to="{ name: 'productdetail', params: { id: item.id } }"
                push
                >{{ item.cnname }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <slot-footer :pid="id" tid="1004" type="3" :isFav="isFav"></slot-footer>

</template>

<script lang="ts">
import { defineComponent, ref, reactive, watch, inject, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";
import axios from "@/api/axios";
import Swiper from "swiper";


export default defineComponent({
  name: "instrumentdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
        onMounted(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
      onMounted(() => {
          nextTick(() => {
          const swiper = new Swiper(".swiper-container", {
            loop: false, // 循环模式选项
            autoplay: true,
            slidesPerView: 1,
            initialSlide: 0,
            loopAdditionalSlides: 3,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            // navigation: {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev',
            // },
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
            },
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },

            },
          });
        });
      });

    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const instrument = ref({});
    const list = ref([]);
    const secListComp = ref([]);
    const amclass = ref(null);
    const comp = ref({});
    const endHostList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);


    function getData(id: any) {
      api
        .get("/M/Product/InstrumentDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          instrument.value = data.instrument;
          document.title = data.instrument.proName + "-普天同创";
          // isFav.value = data.isFav;
          endHostList.value = data.endHostList;
          const isPc = navigatorType();
          if (isPc) {
            window.location.href = `http://www.gbw114.com//p_${data.instrument.classID}/p_${data.instrument.proid}.html`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value);
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        console.log(to, from);
        if (to[1].id && !from[1].valid && !from[1].validate) {
          getData(to[1].id as string);
        }
      }
    );

    const loadOption = {
      text: "加载中...",
      color: "#b81462",
      textColor: "#b81462",
    };
    return {
      instrument,
      list,
      amclass,
      comp,
      id,
      classId,
      isFav,
      endHostList,
      loadOption,
      secListComp,
      listCompLen,
    };
  },
});
</script>


<style lang="scss">

.instrumentDetail {
  video {
    width: 100%;
  }
  .product-wrap {
    padding-bottom: 50px;
  }
  .title-top {
    margin-top: 40px;
  }
  .product-pic img {
    width: 375px;
    height: 375px;
    display: block;
  }

  .product-info {
    position: relative;
    padding: 10.013px;
    padding-top: 21.487px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 6px solid #f4f4f4;
  }

  .product-name {
    width: 255px;
    color: #444444;
    font-size: 16.012px;
    position: relative;
    font-weight: bold;
  }

  .operation {
    position: absolute;
    right: 10.013px;
    top: 50%;
    transform: translateY(-50%);
  }
  .operation a {
    color: #cc3333;
    font-size: 15px;
    font-weight: 700;
  }

  .product-detail .title,
  .recommend-title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
    border-bottom: 1px solid #f4f4f4;
  }

  .recommend-title {
    margin-top: 0.5rem;
  }
  .product-detail .title::after,
  .recommend-title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #b81462;
    margin-top: -6.487px;
  }

  table {
    width: 355.013px !important;
    border: none;
    margin: 0 auto;
    margin-top: 19.988px;
    border-collapse: collapse;
    border-color: #ccc;
  }

  thead {
    width: 355.013px;
    background-color: #b81462;
    border: 1px solid #b81462;
  }
  th {
    color: #fff;
    text-align: left;
    height: 30px;
    box-sizing: border-box;
    padding: 0 9px;
    line-height: 30px;
    font-size: 13.988px;
  }
  td {
    font-size: 13.988px;
    color: #666666;
    padding: 10.013px;
    word-break: break-word;
  }
  .detail-content td{
    border:1.0000pt solid #ccc;
  }
  .content .detail-content span{
    font-size: 14px !important;
    font-family:Microsoft YaHei !important;
  }
  .name {
    width: 117px;
    box-sizing: border-box;
    border-right-color: #e5e5e5;
    background-color: #f2f2f2;
    line-height: 25.013px;
  }
  .detail-name {
    width: 49.987px;
    box-sizing: border-box;
  }
  .other-view {
    margin-top: 19.988px;
    border-top: 1px solid #eee;
  }
  .other-view .title {
    text-align: justify;
    font-size: 16.012px;
    color: #666666;
    line-height: 25.013px;
    margin: 19.988px 10.013px;
    font-weight: bold;
  }

  .other-view .content .detail-title {
    width: 355.013px;
    margin: 0 auto;
    font-size: 16.012px;
    color: #444;
    position: relative;

    margin-top: 19.988px;
    box-sizing: border-box;
  }

  .other-view .content .detail-content {
    width: 355.013px;
    margin: 0 auto;
    font-size: 14px;
    color: #444;
    position: relative;

    box-sizing: border-box;
    line-height:25px;
  }

  .pdt_detail_box {
    font-size: 16.012px;

    color: #444;
    width: 375px;
    box-sizing: border-box;
    width: 355.013px;
    margin: 0 auto;
  }

  .info-left {
    margin: 0 auto;
    text-align: center;
  }

  .productViews {
    width: 109.987px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #b81462;
    color: #fff;
    border-radius: 36px;
    margin: 0 auto;
    margin-top: 7.987px;
  }

  .other-view .content a {
    text-align: justify;
    font-size: 16.012px;
    line-height: 25.013px;
    display: block;
    color: #666666 !important;
    margin: 12.488px 10.013px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .recommend {
    width: 355.013px;
    text-align: left;
    margin: 0 auto;

    box-sizing: border-box;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .recommend .title {
    padding-left: 0.53301rem;
    font-size: 0.4rem;
    color: #444;
    position: relative;
    box-sizing: border-box;
    height: 1.17301rem;
    line-height: 1.17301rem;
    border-bottom: 0.02667rem solid #f4f4f4;
    margin-top: 0.5rem;
  }
  .recommend .title::after {
    content: "";
    position: absolute;
    width: 0.05333rem;
    left: 0.26701rem;
    top: 50%;
    height: 0.34699rem;
    background-color: #b81462;
    margin-top: -0.17299rem;
  }
  .recommend li {
    position: relative;
  }

  .recommend li::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 10.013px;
    width: 4.988px;
    height: 4.988px;
    background: #7f7f7f;
    border-radius: 50%;
  }

  .recommend a {
    text-align: justify;
    font-size: 13.988px;
    line-height: 25.013px;
    display: block;
    color: #666666;
    margin: 10.013px 0;
    overflow: hidden;
    padding-left: 10.013px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .recommend h3 {
    margin: 0;
    font-weight: normal;
  }

  .recommend-title {
    padding-left: 19.988px;
    font-size: 15px;
    color: #444;
    position: relative;
    width: 375px;
    box-sizing: border-box;
    height: 43.988px;
    line-height: 43.988px;
    border-bottom: 1px solid #f4f4f4;
  }

  .recommend-title {
    margin-top: 0.5rem;
  }
  .recommend-title::after {
    content: "";
    position: absolute;
    width: 2px;
    left: 10.013px;
    top: 50%;
    height: 13.012px;
    background-color: #b81462;
    margin-top: -6.487px;
  }
}


</style>
