
import { defineComponent, ref, reactive, watch, inject, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api/axios";
import useFootPrint from "@/hooks/useFootPrint";
import SlotFooter from "@/components/common/SlotFooter.vue";
import Loading from "@/components/UI/Loading";
import navigatorType from "@/utils/browser";
import axios from "@/api/axios";
import Swiper from "swiper";


export default defineComponent({
  name: "instrumentdetail",
  components: {
    SlotFooter,
    Loading,
  },
  setup() {
        onMounted(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      });
      onMounted(() => {
          nextTick(() => {
          const swiper = new Swiper(".swiper-container", {
            loop: false, // 循环模式选项
            autoplay: true,
            slidesPerView: 1,
            initialSlide: 0,
            loopAdditionalSlides: 3,
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            // navigation: {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev',
            // },
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
            },
            on: {
              observerUpdate: function () {
                this.slideTo(0);
              },

            },
          });
        });
      });

    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const isFav = ref(false);
    const instrument = ref({});
    const list = ref([]);
    const secListComp = ref([]);
    const amclass = ref(null);
    const comp = ref({});
    const endHostList = ref([]);
    const { setFootItem } = useFootPrint();
    const listCompLen = ref(0);


    function getData(id: any) {
      api
        .get("/M/Product/InstrumentDetail/" + id)
        .then((res): void => {
          const data = res.data.obj;
          instrument.value = data.instrument;
          document.title = data.instrument.proName + "-普天同创";
          // isFav.value = data.isFav;
          endHostList.value = data.endHostList;
          const isPc = navigatorType();
          if (isPc) {
            window.location.href = `http://www.gbw114.com//p_${data.instrument.classID}/p_${data.instrument.proid}.html`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData(id.value);
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        console.log(to, from);
        if (to[1].id && !from[1].valid && !from[1].validate) {
          getData(to[1].id as string);
        }
      }
    );

    const loadOption = {
      text: "加载中...",
      color: "#b81462",
      textColor: "#b81462",
    };
    return {
      instrument,
      list,
      amclass,
      comp,
      id,
      classId,
      isFav,
      endHostList,
      loadOption,
      secListComp,
      listCompLen,
    };
  },
});
